import React, { Fragment } from "react";
import { Button, Container } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import { useModal } from "./UI/ContactUsModal";
import { useIsMobile } from "../utils/hooks";

const TheaterSchedule = () =>
{
  const { setIsContactDialogOpen } = useModal();
  const isMobile = useIsMobile();

  return (
    <Fragment>
      <Header />

      <section className="banner-theater-schedule-wrapper" style={{ backgroundImage: "url(/img/banners/banner-theater-schedule.png)" }}>
        <Container>
          <h1>JADPRO Live 2021 Product Theater Schedule</h1>
        </Container>
      </section>

      <section className="product-theater-schedule-cards">
              <Container>
                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">Kashmir Horton MSN, RN, OCN</p>
                    <p className="event-name">A Treatment Option for Patients with Myelodysplastic Syndromes</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Friday, October 8, 2021<br />11:00 am – 11:45 am EST</p>
                    <a href="https://jadpro.6connex.com/event/jadprolive/en-us#!/ProductTheater" target="_blank" rel="noreferrer" className="arrow-link-pink">View <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-right.svg" } alt="Arrow Right" /></a>
                  </div>
                </div>

                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">Karen Grooms, MSN, APRN</p>
                    <p className="event-name">A Treatment Option for Patients with Advanced or Metastatic Gastric Cancer, Gastroesophageal Junction Cancer and Esophageal Adenocarcinoma</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Friday, October 8, 2021<br />2:00 pm - 2:45 pm EST</p>
                    <a href="https://jadpro.6connex.com/event/jadprolive/en-us#!/ProductTheater" target="_blank" rel="noreferrer" className="arrow-link-pink">View <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-right.svg" } alt="Arrow Right" /></a>
                  </div>
                </div>

                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">Felipe Novoa, APRN, FNP-BC</p>
                    <p className="event-name">Treatment Option for Patients with Acute Myeloid Leukemia</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Thursday, October 14, 2021<br />4:30 pm – 5:15 pm EST</p>
                    <a href="https://jadpro.6connex.com/event/jadprolive/en-us#!/ProductTheater" target="_blank" rel="noreferrer" className="arrow-link-pink">View <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-right.svg" } alt="Arrow Right" /></a>
                  </div>
                </div>

                <div className="product-theater-schedule-card">
                  <div className="event-speaker-name-wrapper">
                    <p className="speaker-name">Kimberly A Capron, MSN, AGPCNP-BC, OCN</p>
                    <p className="event-name">A Treatment Option for the Management of Certain Patients with First-Line Non-Small Cell Lung Cancer</p>
                  </div>

                  <div className="event-date-wrapper">
                    <p className="date">Friday, October 15, 2021<br />2:15 pm - 3:00 pm EST</p>
                    <a href="https://jadpro.6connex.com/event/jadprolive/en-us#!/ProductTheater" target="_blank" rel="noreferrer" className="arrow-link-pink">View <img src={ process.env.PUBLIC_URL + "/img/icons/arrow-right.svg" } alt="Arrow Right" /></a>
                  </div>
                </div>
              </Container>
            </section>

      { !isMobile &&
        <section className="smiling-guy-wrapper" style={{ backgroundImage: "url(/img/banners/banner-smiling-guy.png)" }}>
          <Container>
            <div className="content-wrapper">
              <h2>Looking for something specific?</h2>
              <Button className="button-pink" onClick={ () => setIsContactDialogOpen(true) }>Contact Us</Button>
            </div>
          </Container>
        </section>
      }
      { isMobile &&
        <section className="smiling-guy-wrapper" style={{ backgroundImage: "url(/img/banners/banner-smiling-guy-mobile.png)" }}>
          <Container>
            <div className="content-wrapper">
              <h2>Looking for something specific?</h2>
              <Button className="button-pink" onClick={ () => setIsContactDialogOpen(true) }>Contact Us</Button>
            </div>
          </Container>
        </section>
      }
      <Footer />
    </Fragment>
  );
}

export default React.memo(TheaterSchedule);
